.root {
    width: 100%;
    overflow: hidden;
}

.scroll {
    margin-bottom: -25px;
    padding-bottom: 25px;

    overflow-x: scroll;
}
