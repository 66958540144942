@import "style/mixins.module.scss";

.root {
    width: 100%;
    max-width: 100%;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
}

.question {
    position: relative;

    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;

    &.clickable {
        cursor: pointer;
    }

    @include respond-to("laptop") {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.icon {
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    transition: 0.1s transform linear;
    right: 0;

    &--active {
        transform: rotate(0deg);
    }

    @include respond-to("laptop") {
        top: 16px;
    }
}

.title {
    position: relative;
    margin-bottom: 0;
    padding-right: 40px;

    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        width: 24px;
        height: 24px;

        display: block;

        transform: translate3d(0, -50%, 0);
        transition: transform 400ms ease;
    }

    &_open:after {
        transform: translate3d(0, -50%, 0) rotate(45deg) scale(1.07);
    }
}

.answer {
    overflow: hidden;

    height: 0;

    transition: height 400ms ease;

    &__text {
        margin: 0;
        font-size: 17px;
        line-height: 24px;

        @include respond-to("laptop") {
            font-size: 22px;
            line-height: 32px;
        }
    }
}

.inner {
    opacity: 0;

    transition: opacity 300ms ease;

    &_open {
        opacity: 1;
    }
}
