@import "style/mixins.module.scss";

.title {
    margin-bottom: 32px;

    @include respond-to("laptop") {
        margin-bottom: 0;

        &_with-margin {
            margin-bottom: 16px;
        }
    }
}

.wave-background {
    display: none;
    position: absolute;
    z-index: -1;
    width: 400px;
    height: 480px;
    top: 211px;
    left: -300px;
    background-image: url("/images/teens/wave.svg");
    background-position: center bottom;

    @include respond-to("laptop") {
        display: block;
    }

    @include respond-to("laptop-L") {
        width: 480px;
        left: -300px;
        top: 284px;
    }
}

.description {
    font-size: 17px;
    line-height: 24px;
}

.container-first-image,
.container-second-image {
    display: none;
}

@media (min-width: 1024px) {
    .root_with-images {
        position: relative;
    }

    .column-text-max-width,
    .column-title-max-width {
        width: calc((100% - 40px) / 2);
        padding-top: 50px;
        position: relative;
    }

    .title {
        max-width: 400px;
    }

    .title,
    .description {
        position: relative;
        z-index: 20;
    }

    .container-first-image {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        display: block;
        width: calc((4 / 6) * 100% - 20px);
        height: 478px;
    }

    .container-second-image {
        position: relative;
        margin-top: 132px;
        display: block;
        width: 312px;
        height: 294px;
        z-index: 10;
    }

    .image {
        display: block;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .row_with-images {
        display: flex;
        justify-content: space-between;

        &_block {
            display: block;

            .column:first-child,
            .column:last-child {
                width: 100%;
            }
        }
    }
}

@media (min-width: 1280px) {
    .row {
        display: flex;
        justify-content: space-between;

        &_block {
            display: block;

            .column-without-image:first-child,
            .column-without-image:last-child {
                width: 100%;
            }
        }
    }

    .column-without-image {
        &:first-child {
            width: calc(45% - 80px);
            margin-right: 80px;
        }

        &:last-child {
            width: 55%;
        }
    }
}

@media (min-width: 1440px) {
    .column-title-max-width {
        padding-top: 60px;
    }

    .column-text-max-width {
        padding-top: 170px;
    }

    .container-second-image {
        margin-top: 218px;
    }
}
